import './ClusterMoreInfoColumnRenderer.less';
import { Tooltip } from 'antd';
import { blue } from '@ant-design/colors';
import { format } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import Icon, {
  AimOutlined,
  CrownOutlined,
  DeleteOutlined,
  DesktopOutlined,
  DiffOutlined,
  LaptopOutlined,
  SaveOutlined,
  SwapOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import MoreInfoTag from './MoreInfoTag/MoreInfoTag';
import { ReactComponent as ShieldIcon } from 'images/shield.svg';
import { ReactComponent as CopilotLogo } from 'images/copilot-filled.svg';
import { ReactComponent as SparkStarLogo } from 'images/spark-star.svg';
import { ReactComponent as ChidoriBlackIcon } from 'images/chidori-black-logo.svg';
import { ReactComponent as UpdateIcon } from 'images/update-icon.svg';
import { ReactComponent as DeltaSharingLogo } from 'images/delta-sharing.svg';
import { ReactComponent as NotebookIcon } from 'images/notebook-logo.svg';
import { ReactComponent as ExternalBucketIcon } from 'images/external-bucket.svg';
import { moreInfoSVGIconStyles } from 'utils/clusters';
import { Instance } from 'types/cluster';

function ClusterMoreInfoColumnRenderer({ instance }: { instance: Instance }) {
  const content = (
    <div>
      {instance.featureBits.map(featureBit => (
        <p key={featureBit.featureBits ? featureBit.featureBits.key : ''}>
          {featureBit.featureBits ? featureBit.featureBits.name : ''}
        </p>
      ))}
    </div>
  );

  return (
    <span className="cluster-more-info-column-renderer__wrapper">
      {instance.domain && (
        <Tooltip title={'Cluster Sub Domain'}>
          <MoreInfoTag color="red">
            {instance.domain[0].toUpperCase() + instance.domain.substring(1)}
          </MoreInfoTag>
        </Tooltip>
      )}
      {!instance.vanillaVersion && (
        <MoreInfoTag color="magenta">
          <UpdateIcon style={moreInfoSVGIconStyles} />
          Upgraded
        </MoreInfoTag>
      )}
      {instance.upgrade && (
        <MoreInfoTag color="orange">
          {instance.upgrade === true ? 'Pending Upgrade' : instance.upgrade}
        </MoreInfoTag>
      )}
      {instance.isProvision && (
        <MoreInfoTag color="volcano">
          <UserSwitchOutlined style={moreInfoSVGIconStyles} />
          Provisioned
        </MoreInfoTag>
      )}
      {instance.filestoreMountPath && (
        <MoreInfoTag color="red">Filestore</MoreInfoTag>
      )}

      {instance.enableDeltaShare && (
        <MoreInfoTag color="cyan">
          <DeltaSharingLogo style={moreInfoSVGIconStyles} />
          Delta Sharing
        </MoreInfoTag>
      )}
      {instance.sqliEnabled && <MoreInfoTag color="cyan">SQLI</MoreInfoTag>}
      {instance.incortaXEnabled && <MoreInfoTag color="cyan">SQLX</MoreInfoTag>}
      {instance.subClusters && instance.subClusters.length > 0 && (
        <MoreInfoTag color="cyan">Self Managed</MoreInfoTag>)}
      {instance.isMaintenanceEnabled && (
        <MoreInfoTag color={blue[1]}
          style={{ color: blue[8], borderColor: blue[3] }}>
          Maintenance
        </MoreInfoTag>
      )}
      {instance.rgName && instance.rgName !== '0' && (
        <MoreInfoTag color="purple">{instance.rgName}</MoreInfoTag>
      )}
      {instance.cname && <MoreInfoTag color="purple">CName</MoreInfoTag>}
      {instance.hostName && <MoreInfoTag color="purple">Host</MoreInfoTag>}
      {instance.enableDataAgent && (
        <MoreInfoTag color="cyan">
          <ShieldIcon style={moreInfoSVGIconStyles} />
          Data Agent
        </MoreInfoTag>
      )}
      {!instance.sleeppable && (
        <MoreInfoTag color="red">Always Alive</MoreInfoTag>
      )}
      {instance.isCloned && (
        <MoreInfoTag color="orange">
          <DiffOutlined style={{ ...moreInfoSVGIconStyles, height: '15px' }} />
          Cloned
        </MoreInfoTag>
      )}
      {instance.mlflowEnabled && (
        <MoreInfoTag color="purple">
          <AimOutlined style={moreInfoSVGIconStyles} />
          MLflow
        </MoreInfoTag>
      )}
      {instance.enableOpenAI && (
        <MoreInfoTag color="blue">
          <CopilotLogo style={moreInfoSVGIconStyles} />
          Copilot
        </MoreInfoTag>
      )}
      {instance.isMigrated && (
        <MoreInfoTag color="orange">
          <SwapOutlined style={moreInfoSVGIconStyles} />
          Migrated
        </MoreInfoTag>
      )}
      {instance.isArchived && (
        <MoreInfoTag color="purple">
          <SaveOutlined style={moreInfoSVGIconStyles} />
          Archived
        </MoreInfoTag>
      )}
      {instance.analyticsNodes > 1 && (
        <MoreInfoTag color="volcano">
          {instance.analyticsNodes} Analytics
        </MoreInfoTag>
      )}
      {instance.loaderNodes > 1 && (
        <MoreInfoTag color="volcano">
          {instance.loaderNodes} Loaders
        </MoreInfoTag>
      )}
      <Tooltip title={content}>
        <MoreInfoTag color="green">
          <LaptopOutlined style={moreInfoSVGIconStyles} />
          Feature bits
        </MoreInfoTag>
      </Tooltip>
      {instance.rImage && <MoreInfoTag color="magenta">R Image</MoreInfoTag>}
      {instance.isExcludedOc && (
        <MoreInfoTag color="volcano">OC Excluded</MoreInfoTag>
      )}
      {instance.InstanceChidori?.isChidori && (
        <MoreInfoTag color="orange">
          <ChidoriBlackIcon style={moreInfoSVGIconStyles} />
          Chidori
        </MoreInfoTag>
      )}
      {instance.onDemandLoader && instance.onDemandLoader.enabled && (
        <MoreInfoTag color="blue">On-Demand Loader</MoreInfoTag>
      )}
      {instance.mysqlVersion && (
        <MoreInfoTag color="blue">MySQL {instance.mysqlVersion}</MoreInfoTag>
      )}
      {instance.enableBusinessUserNotebook && (
        <MoreInfoTag color="gold">
          <NotebookIcon style={moreInfoSVGIconStyles} />
          Business User Notebook
        </MoreInfoTag>
      )}
      {instance.deletedAt && (
        <Tooltip
          title={format(new Date(instance.deletedAt), 'dd-MM-yyyy hh:mm a')}
        >
          <MoreInfoTag color="gray">
            <DeleteOutlined style={moreInfoSVGIconStyles} />
            Deleted
          </MoreInfoTag>
        </Tooltip>
      )}
      {(instance.externalBucket || instance.configuredAsDist) && (
        <Tooltip
          title={`External Bucket: ${instance.externalBucket ? 'Source' : 'Destination'
            }`}
        >
          <MoreInfoTag
            color={blue[1]}
            style={{ color: blue[8], borderColor: blue[3] }}
          >
            <ExternalBucketIcon style={moreInfoSVGIconStyles} />
            <FormattedMessage id="clusterPage.displayVersions" />
          </MoreInfoTag>
        </Tooltip>
      )}
      {instance.incortaSparkVersion && (
        <MoreInfoTag color="blue">
          <Icon
            key="spark"
            component={SparkStarLogo}
            alt="Spark"
            style={moreInfoSVGIconStyles}
          />
          Spark {instance.incortaSparkVersion}
        </MoreInfoTag>
      )}
      {instance.enableDataStudio && (
        <MoreInfoTag color="blue">
          <DesktopOutlined style={moreInfoSVGIconStyles} /> Data Studio
        </MoreInfoTag>
      )}
      {instance.isPremium && (
        <MoreInfoTag color="gold">
          <CrownOutlined style={moreInfoSVGIconStyles} />
          Premium
        </MoreInfoTag>
      )}
    </span>
  );
}

export default ClusterMoreInfoColumnRenderer;
