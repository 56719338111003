export enum modalKeys {
  EDIT_CLUSTER,
  CLUSTER_INFO,
  UPGRADE,
  CLONE,
  MIGRATION,
  LOGS,
  LDAP,
  TRANSFER_OWNERSHIP,
  AUDIT_HISTORY,
  PROGRESS,
  BLOCK_URL,
  DELETE,
  ARCHIVE,
  CONNECTION,
  REFRESH,
  PROBE_CHECK,
  LIBRARIES,
  COLLECT_METRICS,
  FEATURE_BITS,
  SECRETS,
  RLanguage,
  USER_ROLES,
  CUSTOMIZE,
  SLA_COST,
  CLUSTER_MAINTENANCE,
  ERROR,
  SCHEDULE,
  LOCK_WINDOW,
  MASS_LOCK_INTERFACE,
  SPARK_MASTER,
  NOTEBOOK,
  AUTO_SCALING,
  DISK_UPSIZE,
  EXTERNAL_BUCKET,
  PREMIUM_PACKAGE,
  REMOVE_PREMIUM_PACKAGE,
}
export type ErrorModalType = {
  message: string;
  responseMessages: ResponseMessage[];
};

export type ResponseMessage = {
  property: string;
  message: string;
  status: string;
};

export const platformsMap = {
  gc: 'Google Cloud',
  az: 'Microsoft Azure',
  'az-saas': 'Microsoft Azure SaaS',
};

export type ClusterModalCountType = 'single' | 'multiple';

export const clustersSearchColumns = [
  'Name',
  'Owner Email',
  'Status',
  'Cost Center',
  'Organization',
  'Incorta Version',
  'Custom Build',
  'Size',
  'Created At',
  'Deleted',
  'Data Agent',
  'Cloned',
  'Migrated',
  'SQLX',
  'SQLI',
  'Self Managed',
  'Alive',
  'Feature Bit',
  'Platform',
  'Region',
  'Resource Group',
  'Cost Type',
  'SLA Type',
  'Spark Version',
  'Chidori',
  'Premium',
  'Cluster Code'
];

export const notificationSearchColumns = [
  'platform',
  'region',
  'operator',
  'sla type',
  'organization',
  'name',
  'email',
  'access role',
];

export const usersSearchColumns = [
  'Full Name',
  'Email',
  'Company',
  'Cost Center',
  'Organization',
  'Created At',
  'User Role',
  'Partner',
  'account type',
];

export const partnersSearchColumns = [
  'Name',
  'Email',
  'Clusters Count',
  'Platform',
  'Clusters Size',
];
