import { InstanceChidori } from './chidori';
import { Role } from 'types/user';

export type CSize =
  | 'small'
  | 'medium'
  | 'larges1'
  | 'larges2'
  | 'larges3'
  | 'larges4'
  | 'large3x'
  | 'larges5'
  | 'larges6'
  | 'larges7';

interface Tanent {
  id: number;
  name: string;
  data_size: number;
  requied_cpu: number;
  required_memory: number;
  default: boolean;
  createdAt: string;
  updatedAt: string;
}

interface User {
  id: string;
  fullName: string;
}

export interface Instance {
  id: string;
  name: string;
  namespace: string;
  cmc: number;
  incorta: number;
  pyPackages: string;
  whitelistedIPs: string[] | null;
  prefix: string;
  customBuild: string | null;
  customBuildName: string | null;
  nodepoollabel: string | null;
  sqliEnabled: boolean;
  incortaXEnabled: boolean;
  enableBusinessUserNotebook: boolean;
  businessUserNotebookCount: number | null;
  enableWelcome: boolean;
  enableChat: boolean;
  enableDataAgent: boolean;
  enableOnboarding: boolean;
  enableAnalytics: boolean;
  concurrentSessions: number;
  dataStorePort: string;
  sqlInterfacePort: string;
  status: string;
  installation: string;
  platform: string;
  filestoreMountPath: string;
  sleeppable: boolean;
  allowBackup: boolean;
  image: string;
  csize: CSize | null;
  organization: string;
  cost_center: string;
  hostName: string | null;
  csize2: CSize | null;
  analyticsSizeID: number;
  loaderSizeID: number;
  enableProbeCheck: boolean;
  tenantUsers: number;
  viewerUsers: number | null;
  analyzerUsers: number | null;
  cname: string | null;
  availableDisk: string;
  consumedData: string | null;
  dsize: string;
  dsizeLoader: string;
  vanillaVersion: boolean;
  newPackagesAquired: boolean;
  externalCMC: boolean;
  minExecutors: number;
  implementationVersion: number;
  loaderNodes: number;
  analyticsNodes: number;
  zkReplicas: number;
  maxExecutors: number;
  remoteStorage: boolean;
  blockFromUrl: boolean;
  idleTime: number;
  sparkMem: number;
  sparkCpu: number;
  cpu: number | null;
  memory: string | null;
  content: string | null;
  initiatedUpgradeAt: string | null;
  runningAt: string | null;
  description: string | null;
  auditUpdatedAt: string | null;
  isArchived: boolean;
  userID: string;
  tenantID: number;
  costTypeID: number;
  slaTypeID: string | null;
  region: string;
  zone: string;
  k8sClusterCode: string;
  timezone: string;
  isCloned: boolean;
  clonedFromBackup: string | null;
  currentSparkImage: string | null;
  sparkClusterModePyPackages: string | null;
  isMigrated: boolean;
  oldRegion: string | null;
  oldZone: string | null;
  oldK8sClusterCode: string | null;
  rImage: boolean;
  rgName: string | null;
  environmentType: string | null;
  lockedActions: string[] | null;
  isProvision: boolean;
  isExcludedOc: boolean;
  isChidori: boolean;
  isMaintenanceEnabled: boolean;
  maintenanceMessage: string;
  enableOpenAI: boolean;
  mlflowEnabled: boolean;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  user: UserObjectInInstance;
  onDemandLoader: {
    id: number;
    enabled: boolean;
    active: boolean;
    instanceID: string;
  };
  instanceServices: InstanceServices[] | null;
  InstanceChidori: InstanceChidori | null;
  autoScales: AutoscaleStatus | null;
  pythonPackages: InstalledPythonPackage[] | null;
  analyticsSize: AnalyticsLoaderSize;
  loaderSize: AnalyticsLoaderSize;
  featureBits: FeatureBit[] | null;
  domain: string;
  upgrade?: boolean;
  enableDeltaShare?: boolean;
  //#region External Bucket
  /** true → source; false → destination or not yet set */
  externalBucket: boolean;
  /** string → source cluster name */
  configuredAsDist: string | null;
  /** string → bucket path */
  externalBucketName: string | null;
  /** string → bucket path */
  absoluteExternalBucketName: string | null;
  /** string → bucket path */
  incortaSparkVersion: string | null;
  //#endregion
  isPremium?: boolean;
  enableDataStudio?: string;
  subClusters: SubCluster[] | null
  mysqlVersion?: string | null;
}

export type InstanceServices = {
  id: number;
  instanceID: string;
  cmc_status: string;
  analytics_status: string;
  loader_status: string;
  spark_status: string;
  zookeeper_status: string;
  createdAt: string;
  updatedAt: string;
};

export type AnalyticsLoaderSize = {
  id: number;
  name: string;
  displayName: string;
  nodeLabel: string;
  regionCloudProviderID: number;
  memoryRequest: number;
  memoryLimit: number;
  memoryText: number;
  ipu: number;
  cpu: number;
  icc: number;
  order: number;
  canBeDeleted: boolean;
  isCustomSize: boolean;
  overCommitment: string;
  createdAt: string;
  updatedAt: string;
};

export type InstancesResponse = {
  instances: Instance[];
  total: number;
  limit: number;
  page: number;
  pagesCount: number;
};

export type UserObjectInInstance = {
  id: string;
  email: string;
  accountManager: boolean;
  password: string;
  passwordResetToken?: null;
  passwordResetExpires?: null;
  lastLoginAt: string;
  roleID: number;
  industryID: number;
  confirmationToken: string;
  confirmed: boolean;
  admin: boolean;
  blocked: boolean;
  permission: string;
  userConsent: string;
  acceptTerms: boolean;
  invitedAccount: boolean;
  confirmedAt: string;
  fullName: string;
  optInLocation: string;
  trialExpired: boolean;
  trialExpiration: string;
  firstName: string;
  lastName: string;
  phone?: null;
  company: string;
  jobTitle: string;
  gender?: null;
  location?: null;
  picture?: null;
  country: string;
  region: string;
  timezone: string;
  roles: string;
  extendedTrial: boolean;
  enableMarketplace: boolean;
  credit: number;
  organization: string;
  cost_center: string;
  isPartner: boolean;
  isProvision: boolean;
  lockedActions?: null;
  createdAt: string;
  updatedAt: string;
  deletedAt?: null;
  role: Role;
};

export interface InstalledPythonPackage {
  id: number;
  name: string;
  analyticsState: pythonInstallationStateEnum;
  loaderState: pythonInstallationStateEnum;
  chidoriState: pythonInstallationStateEnum;
  type?: string;
  instanceID: string;
  createdAt: string;
  updatedAt: string;
}

export type InstalledPythonPackageVersionSeparated = InstalledPythonPackage & {
  packageName: string;
  packageVersion: string;
};

export enum pythonInstallationStateEnum {
  'installing' = 'installing',
  'succeeded' = 'succeeded',
  'failed' = 'failed',
  'uninstalling' = 'uninstalling',
}

export interface AutoscaleStatus {
  id: number;
  enabled: boolean;
  requestDelay: number;
  coolDelay: number;
  rejectedQueriesUp: number;
  rejectedQueriesDown: number;
  consecutiveQueries: number;
  instanceID: string;
}

type InstanceService = {
  analytics_status: string;
  cmc_status: string;
  createdAt: string;
  id: number;
  instanceID: string;
  loader_status: string;
  spark_status: string;
  updatedAt: string;
  zookeeper_status: string;
};

type FeatureBit = {
  id: number;
  instanceID: string;
  featureBitID: string;
  createdAt: string;
  updatedAt: string;
  featureBits: {
    id: string;
    name: string;
    key: string;
    enabled: boolean;
    serviceToBeRestarted: string;
    enabledByDefault: boolean;
    dependencies: string;
    updateThrough: string;
    minIncortaVersion: string;
    createdAt: string;
    updatedAt: string;
  };
};

export interface NotificationInfo {
  hasUnRead: boolean;
  notifications: Notification[];
}

export interface Notification {
  createdAt: string;
  event: string;
  eventType: string;
  id: string;
  instanceID: string;
  message: string;
  updatedAt: string;
  read: boolean;
}

export interface SubCluster {
  name: string;
  createdAt: string;
  id: number;
  instanceID: string;
  nodes: number;
  size: string;
  state: string;
  status: string;
  type: string;
  updatedAt: string;
  url: string;
  cpu: number;
  ipu: number;
}
export interface Regions {
  regions: Region[];
}

export interface Region {
  region: string;
  regionAlias: string;
  url: string;
  zone: string;
  cluster_code: string;
  isAvailable: boolean;
  platform: string;
  app_id: string;
}

export interface InstancesData {
  instances: InstanceInfo[];
  notifications?: NotificationInfo;
}

export interface InstanceInfo {
  instance: Instance;
  services: Service[];
  serverConfiguration: ServerConfiguration[];
}

export interface Service {
  name: string;
  status: string;
  url: string;
}

export interface ServerConfiguration {
  name: string;
  text: string;
  description: string;
}

export type ClusterStatus =
  | 'running'
  | 'starting-up'
  | 'stopping'
  | 'creating'
  | 'sleeping'
  | 'undefined';

export interface GetClusterData {
  userId: string;
  instanceName: string;
}

export interface DeleteClusterData {
  userId: string;
  instanceName: string;
}

export interface ImagesResult {
  images: {
    image: string;
    tags: {
      release: string;
    };
  }[];
}

export interface TenantsResult {
  tenants: {
    id: number;
    name: string;
    data_size: number;
    requied_cpu: number;
    required_memory: number;
    default: boolean;
    createdAt: string;
    updatedAt: string;
  }[];
}

interface Size {
  type: CSize;
  description: string;
  cpu: string;
  memory: string;
}

export interface SizesResult {
  sizes: {
    type: CSize;
    description: string;
    cpu: string;
    ipu: number;
    memory: string;
    isTrialAvailable: boolean;
  }[];
}

export interface ClusterData {
  name: string;
  csize?: CSize;
  dsize?: number;
  image?: string;
  tenantID?: number;
  installation?: string;
  alive?: boolean;
  sleeppable?: boolean;
  pips?: string;
  enableDataAgent?: boolean;
  idleTime?: number;
  region?: any;
  zone?: string;
  k8sClusterCode?: string;
  appId?: string;
  enableOnboarding?: boolean;
}

export interface Subscription {
  token: any;
  configurationName: string;
  servicePrincipalCredentials: string;
  subscriptionID: string;
  resourceGroup: string;
  appID: string;
  password: string;
  tenant: string;
  network: boolean;
  vnName: string;
  subnet1: string;
  subnet2: string;
  subnet3: string;
  subnet4: string;
  subnet5: string;
  region: string;
  userId: string;
  email: string;
  plan: string;
}

export type UpdateClusterData = Partial<ClusterData>;

interface EditInstanceData {
  pips: string;
  csize: CSize;
}

export interface ChangeSqliStateResult {
  message: string;
}

export interface ClusterUpgradeResult {
  message: string;
}

export interface BlueprintItem {
  blueprintCategoryID: number;
  businessFunction: string;
  createdAt: string;
  exportedFile: string;
  id: number;
  name: string;
  position: number;
  updatedAt: string;
  url: string;
  selected?: boolean;
  blueprintCategory: BlueprintCategory;
  type: string;
  tag: string;
  description: string;
}

interface Blueprint {
  blueprints: BlueprintItem[];
  createdAt: string;
  id: number;
  image: string;
  name: string;
  position: null;
  updatedAt: string;
}

export interface BlueprintsResult {
  blueprints: Blueprint[];
  blueprintFunctions: string[];
  blueprintTypes: string[];
}

export interface ImportBlueprintArg {
  names: string;
  tenant: string;
  instanceId: string;
  username: string;
  password: string;
}

export interface ExportBlueprintArg {
  names: string;
  tenant: string;
  instanceId: string;
  username: string;
  password: string;
  exportFolder: string;
}

interface ImportBlueprintValues {
  tenant: string;
  username: string;
  password: string;
  loadFromStaging: boolean;
}

export interface ImportedBlueprint {
  id: number;
  instanceID: string;
  blueprintID: number;
  tenant: string;
  imported: boolean;
  createdAt: string;
  updatedAt: string;
  blueprint: {
    id: number;
    name: string;
    url: string;
    position: number;
    exportedFile: string;
    businessFunction: string;
    blueprintCategoryID: number;
    blueprintCategory: BlueprintCategory;
    createdAt: string;
    updatedAt: string;
  };
}

export interface importedBlueprintsObj {
  [key: string]: ImportedBlueprint[];
}
export interface ImportState {
  state: 'loading' | 'success' | 'failed';
  importedBlueprints: importedBlueprintsObj;
  datasourceMessage?: string;
  failedBlueprints?: ImportedBlueprint['blueprint'][];
  message?: { title?: string; body?: string };
  error?: string;
}

interface BlueprintCategory {
  id: number;
  image: string;
  name: string;
  updatedAt: string;
  createdAt: string;
}

export type TableData = {
  day: Date;
  dayFormat: string;
  incorta: any;
  spark: any;
  total: any;
  isBeforeNow: boolean;
  hours: {
    hour: number;
    incorta: any;
    spark: any;
  }[];
}[];

export interface ConsumptionInfo {
  data: {
    clusters: {
      name: string;
      id: string;
      consumptionAgg: any;
    }[];
  };
}

export interface ValidBlueprintClusters {
  instances: Instance[];
}

export interface SupportToken {
  token: string;
  expiresAt: string;
}

export interface SubClusterCreateData {
  type: string;
  name: string;
  size: string;
  nodes: number;
}

export interface SubClusterUpdateData {
  subCluster: string;
  size: string;
  nodes: number;
}

export type SubClusterStates = 'disconnected' | 'loading' | 'running';

export interface SuspendTimeData {
  availableIdleTimes: {
    key: number;
    display: string;
  }[];
}

export interface AuthorizedUserRole {
  authorizedRoles: Role[];
  createdAt: string;
  status: string;
  updatedAt: string;
  user: {
    id: string;
    email: string;
    confirmed: boolean;
    fullName: string;
    lastLoginAt: string;
  };
}

export interface AuthUserData {
  authorizedUserRoles: AuthorizedUserRole[];
}

export type ClusterUserRole =
  | 'owner'
  | 'accountManager'
  | 'accountAdmin'
  | 'developer';

export interface Whitelist {
  active: boolean;
  createdAt: string;
  description: string;
  id: number;
  instanceID: string;
  range: string;
  updatedAt: string;
}

export interface WhiteListData {
  whitelists: Whitelist[];
}

export interface AzureOffers {
  offers: AzureOffer[];
}

export interface AzureOffer {
  id: number;
  email: string;
  plan: string;
}

export enum InstancePlatform {
  GC = 'gc',
  AZURE = 'az',
  AZURE_SAAS = 'az-saas',
  AWS = 'aws',
}

export type ClusterSimpleSearch<T> = {
  instances: T[];
};

export type CustomizationResult = {
  customization: [];
};
