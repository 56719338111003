import { useContext, useState } from 'react';
import './Clusters.less';
import _ from 'lodash';
import {
  Typography,
  Select,
  Input,
  DatePicker,
  Switch,
  Tag,
  Badge,
} from 'antd';
import { format } from 'date-fns';
import moment from 'moment';
import { ColumnsType } from 'antd/lib/table';
import SearchableTable from 'components/ui/SearchableTable/SearchableTable';
import {
  useCostTypes,
  useFeatureBits,
  useGetClusters,
  useIncortaVersions,
  useOperators,
  useSLAType,
} from 'hooks/queries';
import {
  antDDateFormat,
  savedDateFormat,
  seachableTablePageSizeOptions,
} from 'utils/constants';
import ClusterTableActions from 'components/Clusters/ClusterTableActions/ClusterTableActions';
import SelectedClustersActions from 'components/Clusters/SelectedClustersActions/SelectedClustersActions';
import ClusterMoreInfoColumnRenderer from 'components/Clusters/ClusterMoreInfoColumnRenderer/ClusterMoreInfoColumnRenderer';
import ClustersModalsWrapper from 'components/Clusters/ClustersModalsWrapper/ClustersModalsWrapper';
import { Instance, InstancePlatform } from 'types/cluster';
import {
  SetActiveModalContext,
  SetModalErrorContext,
} from 'components/Clusters/contexts/ClusterContext';
import {
  modalKeys,
  clustersSearchColumns,
  platformsMap,
} from 'components/Clusters/helper';
import useClusterSize from 'hooks/useClusterSize';
import AzureLogo from 'images/azure-a-logo.svg';
import AzureSaaSLogo from 'images/azure-saas-logo.svg';
import GoogleCloudLogo from 'images/google-cloud-logo.svg';

const { Title } = Typography;
const { Option, OptGroup } = Select;

function Clusters() {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');

  const { ClusterSizeEnum } = useClusterSize();
  const [currentInstance, setCurrentInstance] = useState<Instance>(null);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const setActiveModal = useContext(SetActiveModalContext);
  const setError = useContext(SetModalErrorContext);

  function handleShowErrorModal({ message, responseMessages }) {
    setError({ message, responseMessages });
    setActiveModal(modalKeys.ERROR);
  }

  const clearSelection = () => {
    setSelectedRowKeys([]);
  };

  const onSelectChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    preserveSelectedRowKeys: true,
    onChange: onSelectChange,
  };

  const getStatusIcon = value => {
    switch (value) {
      case 'running':
        return <Badge status="processing" color="green" />;
      case 'starting':
      case 'loading':
        // return '◒';
        return <div className="clusters__status-tag-loading" />;
      case 'stopping':
        // return '◓';
        return <div className="clusters__status-tag-stopping" />;
      case 'disconnected':
        return <div className="clusters__status-tag-disconnected" />;
      default:
        return ''; // Default color for unknown statuses
    }
  };
  const getStatusColor = value => {
    switch (value) {
      case 'running':
        return 'green';
      case 'starting':
      case 'loading':
        return 'gold';
      case 'disconnected':
        return 'red';
      default:
        return 'gray'; // Default color for unknown statuses
    }
  };

  const currentSelectedRows = (id: string) => {
    const selectedClusters = [...selectedRowKeys];
    _.remove(selectedClusters, function (c) {
      return c === id;
    });
    onSelectChange(selectedClusters);
  };

  const [limit, setLimit] = useState(seachableTablePageSizeOptions[0]);
  const {
    data: result,
    isLoading: isPending,
    refetch: refetchClusters,
  } = useGetClusters({ page, search, limit });

  const clusters = result?.data?.instances;
  const current = result?.data?.page;
  const total = result?.data?.total;

  const {
    data: operatorsData,
    isLoading: isOperatorsLoading,
    isError: hasOperatorsErrors,
  } = useOperators();

  const gcRegions = operatorsData?.filter(
    operator => operator.platform === InstancePlatform.GC,
  );
  const azRegions = operatorsData?.filter(
    operator => operator.platform === InstancePlatform.AZURE,
  );
  const azSaaSRegions = operatorsData?.filter(
    operator => operator.platform === InstancePlatform.AZURE_SAAS,
  );

  const {
    data: incortaVersions,
    isLoading: isIncortaVersionsLoading,
    isError: hasIncortaVersionsErrors,
  } = useIncortaVersions();

  const {
    data: featureBits,
    isLoading: isFeatureBitsLoading,
    isError: hasFeatureBitsErrors,
  } = useFeatureBits();

  const {
    data: costTypes,
    isLoading: isCostTypesLoading,
    isError: hasCostTypesErrors,
  } = useCostTypes();

  const fieldNames = {
    name: 'name',
    status: 'status',
    costCenter: 'costCenter', //
    owner: 'owner',
    organization: 'organization',
    platform: 'platform',
    region: 'region',
    version: 'version',
    customBuild: 'Custom Build',
    size: 'size',
    createdAt: 'createdAt',
    deleted: 'deleted',
    dataAgent: 'dataAgent',
    cloned: 'cloned',
    migrated: 'migrated',
    incortaXEnabled: 'sqlx',
    sqliEnabled: 'sqli',
    subClusters: 'self-managed',
    alive: 'alive',
    featureBit: 'featureBit',
    resourceGroup: 'resourceGroup',
    costType: 'costType',
    runningAt: 'runningAt',
    slaType: 'slaType',
    more: 'more',
    deletedAt: 'deletedAt',
  } as const;

  const columns: ColumnsType<Instance> = [
    {
      title: 'Name',
      dataIndex: fieldNames.name,
      key: 'name',
      width: '15%',
    },
    {
      title: 'Owner',
      render(_, instance) {
        return instance.user.email;
      },
    },
    {
      title: 'Status',
      dataIndex: fieldNames.status,
      render(value) {
        return (
          <Tag color={getStatusColor(value)} className="clusters__status-tag">
            {/* <div> */}
            <span>{getStatusIcon(value)}</span> <span>{value}</span>
            {/* </div> */}
          </Tag>
        );
      },
    },
    {
      title: 'Organization',
      dataIndex: fieldNames.organization,
      width: '10%',
    },
    {
      title: 'Region (Zone)',
      width: '20%',
      render(_, instance) {
        return `${instance.region
          .replace('-', ' ')
          .replace(/(^\w|\s\w)/g, m => m.toUpperCase())} (${instance.zone
            .replace('-', ' ')
            .replace(/(^\w|\s\w)/g, m => m.toUpperCase())})`;
      },
    },
    {
      title: 'Platform',
      width: '10%',
      render(_, instance) {
        let Icon: any;
        switch (instance.platform) {
          case InstancePlatform.GC:
            Icon = GoogleCloudLogo;
            break;
          case InstancePlatform.AZURE:
            Icon = AzureLogo;
            break;
          case InstancePlatform.AZURE_SAAS:
            Icon = AzureSaaSLogo;
            break;
          default:
            break;
        }

        return (
          <section className="clusters__platform-select-label">
            <img
              className="clusters__platform-select-img"
              src={Icon}
              alt={platformsMap[instance.platform]}
            />
            <span>{platformsMap[instance.platform]}</span>
          </section>
        );
      },
    },
    {
      title: 'Version',
      render(_, instance) {
        return (
          <span>
            {instance.customBuildName || instance.customBuild || instance.image}
          </span>
        );
      },
    },
    {
      title: 'Size',
      render(_, instance: Instance) {
        const isAnalyticsAndLoaderSameSize =
          instance?.analyticsSizeID === instance?.loaderSizeID;
        if (isAnalyticsAndLoaderSameSize) {
          return (
            <section className="clusters__size-cell center">
              <label>{instance?.analyticsSize?.displayName}</label>
            </section>
          );
        }
        return (
          <section className="clusters__size-cell">
            <article>
              <u>Analytics</u>
              <br />
              <label>{instance?.analyticsSize?.displayName}</label>
            </article>
            <article>
              <u>Loader</u>
              <br />
              <label>{instance?.loaderSize?.displayName}</label>
            </article>
          </section>
        );
      },
    },
    {
      title: 'Created At',
      dataIndex: fieldNames.createdAt,
      width: '10%',
      render(date) {
        return format(new Date(date), 'dd-MM-yyyy hh:mm a');
      },
    },
    {
      title: 'Last Startup',
      dataIndex: fieldNames.runningAt,
      width: '10%',
      render(date) {
        return date === null
          ? ''
          : format(new Date(date), 'dd-MM-yyyy hh:mm a');
      },
    },

    {
      title: 'More Information',
      width: '15%',
      render(_, instance: Instance) {
        return <ClusterMoreInfoColumnRenderer instance={instance} />;
      },
    },
    {
      title: 'Actions',
      render(_, instance: Instance) {
        return (
          <ClusterTableActions
            instance={instance}
            setCurrentInstance={setCurrentInstance}
            currentSelectedRows={currentSelectedRows}
            refetchClusters={refetchClusters}
          />
        );
      },
    },
  ];

  const fieldsNamesMap = new Map([
    ['Name', 'Name'],
    ['Status', 'Status'],
    ['Cost Center', 'Cost Center'],
    ['Organization', 'Organization'],
    ['Platform', 'Platform'],
    ['Region', 'Region'],
    ['Incorta Version', 'Incorta Version'],
    ['Custom Build', 'Custom Build'],
    ['Size', 'Size'],
    ['Created At', 'Created At'],
    ['Deleted', 'Deleted'],
    ['Data Agent', 'Data Agent'],
    ['Cloned', 'Cloned'],
    ['Migrated', 'Migrated'],
    ['SQLX', 'SQLX'],
    ['SQLI', 'SQLI'],
    ['Self Managed', 'Self Managed'],
    ['Alive', 'Alive'],
    ['Feature Bit', 'Feature Bit'],
    ['Resource Group', 'Resource Group'],
    ['Cost Type', 'Cost Type'],
    ['SLA Type', 'SLA Type'],
    ['Chidori', 'Chidori'],
    ['Cluster Code', 'Cluster Code']
  ]);

  const optionsClusterStatuses = [
    {
      label: 'loading',
      value: 'loading',
    },
    {
      label: 'starting',
      value: 'starting',
    },
    {
      label: 'running',
      value: 'running',
    },
    {
      label: 'disconnected',
      value: 'disconnected',
    },
  ];

  const optionsClusterSizes = Object.keys(ClusterSizeEnum).map(
    (key: string) => {
      const label = ClusterSizeEnum[key as keyof typeof ClusterSizeEnum];
      return { label, value: label };
    },
  );

  const {
    data: sLAType,
    isLoading: isSLATypeLoading,
    isError: hasSLATypeErrors,
  } = useSLAType();

  const filterMapping = new Map(
    clustersSearchColumns?.map(field => [
      field,
      (onChange: (value: any) => void, id: string, defaultValue?: any) => {
        switch (field) {
          case 'Name':
            return (
              <Input
                key={field}
                onChange={e =>
                  onChange({
                    field,
                    id,
                    value: e.target.value,
                  })
                }
                defaultValue={defaultValue}
              />
            );
          case 'Owner Email':
            return (
              <Input
                key={field}
                onChange={e =>
                  onChange({
                    field,
                    id,
                    value: e.target.value,
                  })
                }
                defaultValue={defaultValue}
              />
            );
          case 'Status':
            return (
              <Select
                key={field}
                mode="multiple"
                maxTagCount="responsive"
                options={optionsClusterStatuses}
                onChange={value =>
                  onChange({
                    field,
                    id,
                    value,
                  })
                }
                filterOption={(input, option) => {
                  if (typeof option.label === 'string') {
                    return (
                      +option.label
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }
                  return false;
                }}
                defaultValue={defaultValue ?? []}
              />
            );
          case 'Cost Center':
            return (
              <Input
                key={field}
                onChange={e =>
                  onChange({
                    field,
                    id,
                    value: e.target.value,
                  })
                }
                defaultValue={defaultValue}
              />
            );
          case 'Organization':
            return (
              <Input
                key={field}
                onChange={e =>
                  onChange({
                    field,
                    id,
                    value: e.target.value,
                  })
                }
                defaultValue={defaultValue}
              />
            );
          case 'Custom Build':
            return (
              <Input
                key={field}
                onChange={e =>
                  onChange({
                    field,
                    id,
                    value: e.target.value,
                  })
                }
                defaultValue={defaultValue}
              />
            );
          case 'Size': // size
            return (
              <Select
                key={field}
                mode="multiple"
                maxTagCount="responsive"
                options={optionsClusterSizes}
                onChange={value =>
                  onChange({
                    field,
                    id,
                    value,
                  })
                }
                filterOption={(input, option) => {
                  if (typeof option.label === 'string') {
                    return (
                      +option.label
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }
                  return false;
                }}
                defaultValue={defaultValue ?? []}
              />
            );
          case 'Created At':
            return (
              <DatePicker
                key={field}
                format={antDDateFormat}
                showTime
                onChange={val =>
                  onChange({
                    field,
                    id,
                    value: moment(val).format(savedDateFormat),
                  })
                }
                {...(defaultValue?.length
                  ? { defaultValue: moment(defaultValue[0], savedDateFormat) }
                  : {})}
              />
            );
          case 'Deleted':
            return (
              <Switch
                key={field}
                className="clusters__advanced-search-switch-btn"
                checkedChildren="Is Deleted"
                unCheckedChildren=""
                onChange={val =>
                  onChange({
                    field,
                    id,
                    value: val,
                  })
                }
                defaultChecked={defaultValue}
              />
            );
          case 'Data Agent':
            return (
              <Switch
                key={field}
                className="clusters__advanced-search-switch-btn"
                checkedChildren="Is Data Agent"
                unCheckedChildren=""
                onChange={val =>
                  onChange({
                    field,
                    id,
                    value: val,
                  })
                }
                defaultChecked={defaultValue}
              />
            );
          case 'Cloned':
            return (
              <Switch
                className="clusters__advanced-search-switch-btn"
                key={field}
                checkedChildren="Is Cloned"
                unCheckedChildren=""
                onChange={val =>
                  onChange({
                    field,
                    id,
                    value: val,
                  })
                }
                defaultChecked={defaultValue}
              />
            );
          case 'Migrated':
            return (
              <Switch
                className="clusters__advanced-search-switch-btn"
                key="Migrated"
                checkedChildren="Is Migrated"
                unCheckedChildren=""
                onChange={val =>
                  onChange({
                    field,
                    id,
                    value: val,
                  })
                }
                defaultChecked={defaultValue}
              />
            );
          case 'SQLX':
            return (
              <Switch
                className="clusters__advanced-search-switch-btn"
                key={field}
                checkedChildren="Is SQLX"
                unCheckedChildren=""
                onChange={val =>
                  onChange({
                    field,
                    id,
                    value: val,
                  })
                }
                defaultChecked={defaultValue}
              />
            );
          case 'SQLI':
            return (
              <Switch
                className="clusters__advanced-search-switch-btn"
                key={field}
                checkedChildren="Is SQLI"
                unCheckedChildren=""
                onChange={val =>
                  onChange({
                    field,
                    id,
                    value: val,
                  })
                }
                defaultChecked={defaultValue}
              />
            );
          case 'Self Managed':
            return (
              <Switch
                className="clusters__advanced-search-switch-btn"
                key={field}
                checkedChildren="Has Self Managed"
                unCheckedChildren=""
                onChange={val =>
                  onChange({
                    field,
                    id,
                    value: val,
                  })
                }
                defaultChecked={defaultValue}
              />
            );
          case 'Alive':
            return (
              <Switch
                className="clusters__advanced-search-switch-btn"
                key={field}
                checkedChildren="Is Alive"
                unCheckedChildren=""
                onChange={val =>
                  onChange({
                    field,
                    id,
                    value: val,
                  })
                }
                defaultChecked={defaultValue}
              />
            );
          case 'Platform':
            return (
              <Select
                key={field}
                mode="multiple"
                maxTagCount="responsive"
                options={Object.entries(platformsMap).map(([key, value]) => {
                  let Icon: any;
                  switch (key) {
                    case InstancePlatform.GC:
                      Icon = GoogleCloudLogo;
                      break;
                    case InstancePlatform.AZURE:
                      Icon = AzureLogo;
                      break;
                    case InstancePlatform.AZURE_SAAS:
                      Icon = AzureSaaSLogo;
                      break;
                    default:
                      break;
                  }
                  return {
                    label: (
                      <section className="clusters__advanced-search-platform-select-label">
                        <img
                          className="clusters__platform-select-img"
                          src={Icon}
                          alt={value}
                        />
                        <label>{value}</label>
                      </section>
                    ),
                    value,
                  };
                })}
                onChange={value =>
                  onChange({
                    field,
                    id,
                    value,
                  })
                }
                defaultValue={defaultValue ?? []}
              />
            );
          case 'Region':
            return (
              <Select
                key={field}
                mode="multiple"
                maxTagCount="responsive"
                loading={isOperatorsLoading}
                disabled={hasOperatorsErrors}
                onChange={value =>
                  onChange({
                    field,
                    id,
                    value,
                  })
                }
                defaultValue={defaultValue ?? []}
              >
                <OptGroup
                  label={
                    <div className="clusters__advanced-search-region-select-label">
                      <img
                        className="clusters__region-select-img"
                        src={GoogleCloudLogo}
                        alt="Google Cloud"
                      />
                      <label>Google Cloud</label>
                    </div>
                  }
                >
                  {gcRegions?.map(region => (
                    <Option
                      key={`${region.region}--${region.cluster_code}`}
                      className="clusters__region-select-item"
                      value={region.region}
                    >
                      <label>{region.alias}</label>
                      <label>{region.cluster_code}</label>
                    </Option>
                  ))}
                </OptGroup>
                <OptGroup
                  label={
                    <div className="clusters__advanced-search-region-select-label">
                      <img
                        className="clusters__region-select-img"
                        src={AzureSaaSLogo}
                        alt="Azure-SaaS"
                      />
                      <label>Azure (SaaS)</label>
                    </div>
                  }
                >
                  {azSaaSRegions?.map(region => (
                    <Option
                      key={`${region.region}--${region.cluster_code}`}
                      className="clusters__region-select-item"
                      value={region.region}
                    >
                      <label>{region.alias}</label>
                      <label>{region.cluster_code}</label>
                    </Option>
                  ))}
                </OptGroup>
                <OptGroup
                  label={
                    <div className="clusters__advanced-search-region-select-label">
                      <img
                        className="clusters__region-select-img"
                        src={AzureLogo}
                        alt="Azure"
                      />
                      <label>Azure (Private)</label>
                    </div>
                  }
                >
                  {azRegions?.map(region => (
                    <Option
                      key={`${region.region}--${region.cluster_code}`}
                      className="clusters__region-select-item"
                      value={region.region}
                    >
                      <label>{region.alias}</label>
                      <label>{region.cluster_code}</label>
                    </Option>
                  ))}
                </OptGroup>
              </Select>
            );
          case 'Cluster Code':
            return (
              <Select
                key={field}
                mode="multiple"
                maxTagCount="responsive"
                loading={isOperatorsLoading}
                disabled={hasOperatorsErrors}
                onChange={value =>
                  onChange({
                    field,
                    id,
                    value,
                  })
                }
                defaultValue={defaultValue ?? []}
              >
                <OptGroup
                  label={
                    <div className="clusters__advanced-search-region-select-label">
                      <img
                        className="clusters__region-select-img"
                        src={GoogleCloudLogo}
                        alt="Google Cloud"
                      />
                      <label>Google Cloud</label>
                    </div>
                  }
                >
                  {gcRegions?.map(region => (
                    <Option
                      key={`${region.cluster_code}`}
                      className="clusters__region-select-item"
                      value={region.cluster_code}
                    >
                      {/* <label>{region.alias}</label> */}
                      <label>{region.cluster_code}</label>
                    </Option>
                  ))}
                </OptGroup>
                <OptGroup
                  label={
                    <div className="clusters__advanced-search-region-select-label">
                      <img
                        className="clusters__region-select-img"
                        src={AzureSaaSLogo}
                        alt="Azure-SaaS"
                      />
                      <label>Azure (SaaS)</label>
                    </div>
                  }
                >
                  {azSaaSRegions?.map(region => (
                    <Option
                      key={`${region.cluster_code}`}
                      className="clusters__region-select-item"
                      value={region.cluster_code}
                    >
                      {/* <label>{region.alias}</label> */}
                      <label>{region.cluster_code}</label>
                    </Option>
                  ))}
                </OptGroup>
                <OptGroup
                  label={
                    <div className="clusters__advanced-search-region-select-label">
                      <img
                        className="clusters__region-select-img"
                        src={AzureLogo}
                        alt="Azure"
                      />
                      <label>Azure (Private)</label>
                    </div>
                  }
                >
                  {azRegions?.map(region => (
                    <Option
                      key={`${region.region}--${region.cluster_code}`}
                      className="clusters__region-select-item"
                      value={region.region}
                    >
                      <label>{region.alias}</label>
                      <label>{region.cluster_code}</label>
                    </Option>
                  ))}
                </OptGroup>
              </Select>
            );
          case 'Resource Group':
            return (
              <Input
                key={field}
                onChange={e =>
                  onChange({
                    field,
                    id,
                    value: e.target.value,
                  })
                }
                defaultValue={defaultValue}
              />
            );
          case 'Incorta Version':
            return (
              <Select
                key={field}
                mode="multiple"
                maxTagCount="responsive"
                options={incortaVersions?.map?.(version => {
                  return {
                    value: version,
                    label: version,
                  };
                })}
                loading={isIncortaVersionsLoading}
                disabled={hasIncortaVersionsErrors}
                onChange={value =>
                  onChange({
                    field,
                    id,
                    value,
                  })
                }
                filterOption={(input, option) => {
                  if (typeof option.label === 'string') {
                    return (
                      +option.label
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }
                  return false;
                }}
                defaultValue={defaultValue ?? []}
              />
            );
          case 'Feature Bit':
            return (
              <Select
                key={field}
                mode="multiple"
                maxTagCount="responsive"
                options={featureBits?.map?.(featureBit => {
                  return { label: featureBit.name, value: featureBit.name };
                })}
                loading={isFeatureBitsLoading}
                disabled={hasFeatureBitsErrors}
                onChange={value =>
                  onChange({
                    field,
                    id,
                    value,
                  })
                }
                filterOption={(input, option) => {
                  if (typeof option.label === 'string') {
                    return (
                      +option.label
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }
                  return false;
                }}
                defaultValue={defaultValue ?? []}
              />
            );
          case 'Cost Type':
            return (
              <Select
                key={field}
                mode="multiple"
                maxTagCount="responsive"
                options={costTypes?.map?.(costType => {
                  return { label: costType.name, value: costType.name };
                })}
                loading={isCostTypesLoading}
                disabled={hasCostTypesErrors}
                onChange={value =>
                  onChange({
                    field,
                    id,
                    value,
                  })
                }
                filterOption={(input, option) => {
                  if (typeof option.label === 'string') {
                    return (
                      +option.label
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }
                  return false;
                }}
                defaultValue={defaultValue ?? []}
              />
            );
          case 'SLA Type':
            return (
              <Select
                key={field}
                mode="multiple"
                maxTagCount="responsive"
                options={sLAType?.map?.(sLATypeItem => ({
                  label: sLATypeItem.name,
                  value: sLATypeItem.name,
                }))}
                loading={isSLATypeLoading}
                disabled={hasSLATypeErrors}
                onChange={value =>
                  onChange({
                    field,
                    id,
                    value,
                  })
                }
                filterOption={(input, option) => {
                  if (typeof option.label === 'string') {
                    return (
                      +option.label
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }
                  return false;
                }}
                defaultValue={defaultValue ?? []}
              />
            );
          case 'Spark Version':
            return (
              <Input
                key={field}
                onChange={e =>
                  onChange({
                    field,
                    id,
                    value: e.target.value,
                  })
                }
                defaultValue={defaultValue}
              />
            );
          case 'Chidori':
            return (
              <Switch
                className="clusters__advanced-search-switch-btn"
                key={field}
                checkedChildren="Chidori Enabled"
                unCheckedChildren=""
                onChange={val =>
                  onChange({
                    field,
                    id,
                    value: val,
                  })
                }
                defaultChecked={defaultValue}
              />
            );
          case 'Premium':
            return (
              <Switch
                className="clusters__advanced-search-switch-btn"
                key={field}
                checkedChildren="Premium"
                unCheckedChildren=""
                onChange={val =>
                  onChange({
                    field,
                    id,
                    value: val,
                  })
                }
                defaultChecked={defaultValue}
              />
            );
          default:
            return null;
        }
      },
    ]),
  );

  const searchPlaceholder =
    "Search By Cluster Name, Owner Email, Owner Name, Comapny Or 'Field Name'=Some Value. " +
    "Custom fields include 'deleted', 'installation', 'platform', 'alive'.";
  return (
    <div className="clusters">
      <div className="cluster-header">
        <Title>Clusters</Title>
        {selectedRowKeys.length > 0 && (
          <SelectedClustersActions
            selectedRowKeys={selectedRowKeys}
            clearSelection={clearSelection}
            refetchClusters={refetchClusters}
            handleShowErrorModal={handleShowErrorModal}
          />
        )}
      </div>
      <SearchableTable
        type="Clusters"
        searchPlaceholder={searchPlaceholder}
        modalWidth={720}
        pagination={{
          pageSize: limit,
          current,
          total,
          showSizeChanger: true,
          pageSizeOptions: seachableTablePageSizeOptions,
          onShowSizeChange: (_, size) => {
            setLimit(size);
          },
          onChange(newPage) {
            setPage(newPage);
          },
        }}
        dataSource={clusters}
        loading={isPending}
        columns={columns}
        advancedSearchColumns={clustersSearchColumns}
        onSearch={value => {
          setSearch(value);
          setPage(1);
        }}
        rowSelection={rowSelection}
        filterMapping={filterMapping}
        fieldsNamesMap={fieldsNamesMap}
      />
      <ClustersModalsWrapper
        instance={currentInstance}
        refetchClusters={refetchClusters}
        selectedRowKeys={selectedRowKeys}
        handleShowErrorModal={handleShowErrorModal}
      />
    </div>
  );
}

export default Clusters;
